import { useEffect } from "react";
// import $ from "jquery";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";

import IndexOne from "./pages/IndexOne";
import Download from "./pages/Download";
import Tournament from "./pages/Tournament";
import TournamentDetail from "./pages/TournamentDetail";
import TournamentTeam from "./pages/TournamentTeam";
import TournamentTeamDetail from "./pages/TournamentTeamDetail";
import Donate from "./pages/Donate";
import DonateHistory from "./pages/DonateHistory";
import Wheel from "./pages/Wheel";
import WheelHistory from "./pages/WheelHistory";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetails";
import Report from "./pages/Report";
import ReportHistory from "./pages/ReportHistory";
import Redeem from "./pages/Redeem";
import Setting from "./pages/Setting";
import $ from "jquery";
import { useJwt } from "react-jwt";

import { useUserContext } from "./contexts/UserContext";
function App() {
  const location = useLocation().search;

  const [searchParams, setSearchParams] = useSearchParams();
  const { userAction } = useUserContext();

  useEffect(() => {
    const token = new URLSearchParams(location).get("token");
    if (token) {
      localStorage.setItem("access_token", token);
      if (searchParams.has("token")) {
        searchParams.delete("token");
        setSearchParams(searchParams);
      }
    }
  }, []);
  const { decodedToken, isExpired } = useJwt(
    localStorage.getItem("access_token")
  );
  useEffect(() => {
    if (decodedToken) {
      if (!isExpired) {
        userAction.storeUserDetail(decodedToken.data);
      }
    }
  }, [decodedToken]);

  useEffect(() => {
    function preloader() {
      $("#preloader").delay(0).fadeOut();
    }

    $(window).on("load", function () {
      preloader();
    });
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<IndexOne />} />
        <Route path="/download" element={<Download />} />
        <Route path="/tournaments/:id" exact element={<TournamentDetail />} />
        <Route
          path="/tournaments/:id/teams"
          exact
          element={<TournamentTeam />}
        />
        <Route
          path="/tournaments/:id/teams/:teamId"
          exact
          element={<TournamentTeamDetail />}
        />
        <Route path="/tournaments" exact element={<Tournament />} />
        <Route path="/donate" exact element={<Donate />} />
        <Route path="/donate/history" exact element={<DonateHistory />} />
        <Route path="/redeem" exact element={<Redeem />} />
        //<Route path="/wheel" exact element={<Wheel />} />
        //<Route path="/wheel/history" exact element={<WheelHistory />} />
        //<Route path="/blog" exact element={<Blog />} />
        <Route path="/blog/:id" exact element={<BlogDetail />} />
        //<Route path="/report" exact element={<Report />} />
        //<Route path="/report/history" exact element={<ReportHistory />} />
        <Route path="/setting" exact element={<Setting />} />
        <Route path="*" element={<IndexOne />} />
      </Routes>
    </div>
  );
}

export default App;
