import React from "react";
import Breadcrumb from "../components/aboutus/Breadcrumb";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";

function AboutUs() {
  return (
    <>
      <HeaderOne />
      <main>
        <Breadcrumb />

        <section className="just-gamer-area pb-105 pt-105">
          <div className="just-gamer-shape" />
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-5 col-lg-8">
                <div className="just-gamer-content-box" style={{ height: 400 }}>
                  <div className="mb-3">
                    <div title="Other Tools">
                      <div className="section-title">
                        <h2 className="title">
                          Game <span>Download</span>
                        </h2>
                        <div
                          className="d-flex flex-column gap-4"
                          style={{ marginTop: 50 }}
                        >
                          <a
                            href="http://name.kongor-th.online/HoNInstaller_4.11.0.zip"
                            className="btn w-100 d-flex justify-content-center"
                            target="_blank"
                          >
                            HoN Installer (version 4.11.0) Link 1
                          </a>

                          <a
                            href="http://file.kongor-th.online/HoNInstaller_4.11.0.zip"
                            className="btn w-100 d-flex justify-content-center"
                            target="_blank"
                          >
                            HoN Installer (version 4.11.0) Link 2
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-8 order-2 order-xl-0">
                <div className="just-gamer-content-box" style={{ minHeight: 400 }}>
                  <div id="uncontrolled-tab-example" className="mb-3">
                    <div title="Specs">
                      <div className="section-title">
                        <h2 className="title">
                          Compatible <span>SPECS</span>
                        </h2>
                        <div className="d-flex gap-4">
                          <p>
                            Minimum
                            <br />
                            OS: Window 10
                            <br />
                            Processor: Pentium 4 Processor @ 2.4GHz
                            <br />
                            Memory: 1 GB RAM
                            <br />
                            Graphics: NVDIA Geforce 5700 or Radeon 9600
                            <br />
                            DirectX: Version 9.0c
                            <br />
                            Storage: 2GB available space
                          </p>
                          <p>
                            Recommended
                            <br />
                            OS: Window 10 or Newer
                            <br />
                            Processor: Pentium 4 Processor @ 3GHz or Newer
                            <br />
                            Memory: 2 GB RAM
                            <br />
                            Graphics: NVDIA Geforce FX 6600 or Radeon X600 or
                            Newer
                            <br />
                            DirectX: Version 9.0c
                            <br />
                            Storage: 2GB available space
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 mt-20">
                <div className="just-gamer-content-box" style={{ minHeight: 375 }}>
                  <div className="mb-3">
                    <div>
                      <div className="section-title">
                        <h2 className="title">
                          Driver <span>Download</span>
                        </h2>
                        <div className="d-md-flex gap-4">
                          <div
                            className="d-flex flex-column gap-4"
                            style={{ marginTop: 50 }}
                          >
                            <a
                              href="https://www.microsoft.com/en-us/download/details.aspx?id=35"
                              className="d-flex justify-content-center rounded"
                              style={{
                                width: 150,
                                height: 150,
                                border: 0,
                              }}
                              target="_blank"
                            >
                              <img
                                style={{ objectFit: "cover" }}
                                className="w-100 h-100 rounded"
                                src="/assets/img/logo/direct-x.webp"
                                alt="direct-x"
                              />
                            </a>
                          </div>
                          <div
                            className="d-flex flex-column gap-4"
                            style={{ marginTop: 50 }}
                          >
                            <a
                              href="https://learn.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist?view=msvc-170"
                              className="d-flex justify-content-center rounded"
                              style={{
                                width: 150,
                                height: 150,
                                border: 0,
                              }}
                              target="_blank"
                            >
                              <img
                                style={{ objectFit: "cover" }}
                                className="w-100 h-100 rounded"
                                src="/assets/img/logo/vc-redist.jpg"
                                alt="vc-redist"
                              />
                            </a>
                          </div>
                          <div
                            className="d-flex flex-column gap-4"
                            style={{ marginTop: 50 }}
                          >
                            <a
                              href="https://www.winrar.in.th/download-winrar-for-windows"
                              className="d-flex justify-content-center rounded"
                              style={{
                                width: 150,
                                height: 150,
                                border: 0,
                              }}
                              target="_blank"
                            >
                              <img
                                style={{ objectFit: "cover" }}
                                className="w-100 h-100 rounded"
                                src="/assets/img/logo/win-rar.jpg"
                                alt="win-rar"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default AboutUs;
