import React, { useRef, useState } from "react";
import axios from "axios";
import HeaderOne from "../components/header/HeaderOne";
import Footer from "../components/Footer";
import SimpleReactValidator from "simple-react-validator";
import { useUserContext } from "../contexts/UserContext";
import toast, { Toaster } from "react-hot-toast";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function ContactArea() {
  const [playerUsername, setPlayerUsername] = useState("");
  const [matchId, setMatchId] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const [imageError, setImageError] = useState("");
  const { user, userAction } = useUserContext();
  const [, forceUpdate] = useState();
  const [selectedOption, setSelectedOption] = useState("อ่านหมวดกระทำผิด");

  const options = ["อ่านหมวดกระทำผิด", "เจตนาโยนเกม", "แย่งเลน"];
  const defaultOption = options[0];

  const simpleValidator = useRef(new SimpleReactValidator());

  const selectOption = (e) => {
    setSelectedOption(e.value);
  };

  const handleImageUpload = () => {
    document.getElementById("image-upload").click();
  };

  const login = () => {
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DISCORD_CALLBACK}&response_type=code&scope=identify%20email&prompt=none`;
  };

  const uploadImage = async (e) => {
    const images = [...image];
    const imageNames = [...imageName];
    for (let i = 0; i < e.target.files.length; i++) {
      try {
        const formData = new FormData();
        formData.append("image", e.target.files[i]);
        const headers = {
          Authorization: localStorage.getItem("access_token"),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/user/image-upload`,
          formData,
          { headers }
        );

        images.push(response.data.data);
        imageNames.push(e.target.files[i].name);
      } catch (error) {
        if (error.response.data.errors.image) {
          setImageError(error.response.data.errors.image[0]);
        }
      }
    }
    setImage(images);
    setImageName(imageNames);
  };

  const resetForm = () => {
    setPlayerUsername("");
    setMatchId("");
    setDescription("");
    setImage([]);
    setImageName([]);
    simpleValidator.current.visibleFields = [];
    forceUpdate();
  };

  const submitReport = async (e) => {
    if (simpleValidator.current.allValid()) {
      try {
        const headers = {
          Authorization: localStorage.getItem("access_token"),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/user/report-player`,
          {
            playerUsername: playerUsername,
            matchId: matchId,
            description: description,
            image: image.join(","),
            userId: user.id,
          },
          { headers }
        );
        resetForm();
        toast.success("Report Success !!");
      } catch (error) {
        console.log(error);
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <HeaderOne />
      <main>
        <section className="contact-area black-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="contact-wrap">
                  <div className="contact-title">
                    <h2 className="title">
                      <span>Report Player</span> Any Inconvinient behavior?
                    </h2>
                    <p>
                      We will evaluate your request with in 3 days of business
                      work.
                    </p>
                  </div>
                  {user ? (
                    <form action="#" className="contact-form">
                      <div className="form-grp">
                        <label htmlFor="name">
                          Player Username <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          placeholder="Player Username"
                          onChange={(e) => setPlayerUsername(e.target.value)}
                          value={playerUsername}
                          onBlur={() => {
                            simpleValidator.current.showMessageFor(
                              "playerUsername"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-end mt-2">
                          <small className="text-danger">
                            {simpleValidator.current.message(
                              "playerUsername",
                              playerUsername,
                              "required"
                            )}
                          </small>
                        </div>
                      </div>
                      <div className="form-grp">
                        <label htmlFor="matchId">
                          Match ID <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="matchId"
                          placeholder="Match ID"
                          onChange={(e) => setMatchId(e.target.value)}
                          value={matchId}
                          onBlur={() => {
                            simpleValidator.current.showMessageFor("matchId");
                          }}
                        />
                        <div className="d-flex justify-content-end mt-2">
                          <small className="text-danger">
                            {simpleValidator.current.message(
                              "matchId",
                              matchId,
                              "required"
                            )}
                          </small>
                        </div>
                      </div>
                      <div className="form-grp">
                        <label htmlFor="message">
                          Description <span>*</span>
                        </label>
                        <textarea
                          id="message"
                          placeholder="Describe as much as possible"
                          onChange={(e) => setDescription(e.target.value)}
                          value={description}
                          onBlur={() => {
                            simpleValidator.current.showMessageFor(
                              "description"
                            );
                          }}
                        />
                        <div className="d-flex justify-content-end mt-2">
                          <small className="text-danger">
                            {simpleValidator.current.message(
                              "description",
                              description,
                              "required"
                            )}
                          </small>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2 mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <label
                            style={{ fontSize: 14, fontWeight: 500 }}
                            className="text-white"
                            htmlFor="image"
                          >
                            Image <span style={{ color: "#99ed73" }}>*</span>
                          </label>

                          <div className="d-flex gap-2">
                            {imageName.map((name, index) => (
                              <div key={index}>{name},</div>
                            ))}
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-3">
                          <button
                            onClick={handleImageUpload}
                            className="btn-primary border-0 py-2 rounded w-100"
                            type="button"
                          >
                            Upload Image
                          </button>
                          <input
                            id="image-upload"
                            className="d-none"
                            type="file"
                            accept="image/*"
                            onChange={uploadImage}
                            multiple
                          />
                          {imageError && (
                            <div className="d-flex justify-content-end">
                              <small className="text-danger">
                                {imageError}
                              </small>
                            </div>
                          )}
                          <div className="d-flex justify-content-end">
                            <small className="text-danger">
                              {simpleValidator.current.message(
                                "image",
                                image,
                                "required"
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={submitReport}
                        type="button"
                        className="btn"
                      >
                        submit now
                      </button>
                    </form>
                  ) : (
                    <button
                      onClick={login}
                      type="button"
                      className="btn btn-custom-login d-flex gap-2"
                      style={{ width: 305 }}
                    >
                      <i className="fab fa-discord fa-lg" />
                      <span>Login With Discord First To Report</span>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-5 col-md-8">
                <div className="contact-info-wrap h-100">
                  <div className="contact-info-map p-4 d-flex flex-column gap-4 h-100">
                    {selectedOption === "อ่านหมวดกระทำผิด" ? (
                      <div>
                        <h1 className="text-center mb-5">
                          เงื่อนไขการรายงานผู้เล่น
                        </h1>
                        <p>กฎอาจมีการเปลี่ยนแปลงในอนาคตตามดุลยพินิจของทีมงาน</p>
                        <p>
                          โปรดตรวจสอบให้แน่ใจว่าชื่อในเกมของคุณและผู้เล่นที่
                          รายงานนั้นถูกต้อง สําหรับชื่อที่มีอักขระพิเศษ{" "}
                        </p>
                        <p>
                          อธิบายรายละเอียดมากที่สุดเท่าที่จะเป็นไปได้
                          รวมถึงใส่เวลา การกระทําของผู้เล่น บันทึกภาพและเสียง
                          ฯลฯ โปรดทราบ
                          ว่าการสนทนาแบบเสียงจะไม่ถูกบันทึกในรีเพลย์การเล่น
                          ดังนั้นจะต้องมีคลิปเสียงเป็นหลักฐานหากคุณต้องการ
                          รายงานผู้เล่นที่พูดจาไม่เหมาะสม
                        </p>
                        <p>
                          ผู้เล่นคนใดที่อยู่ในการแข่งขันเมื่อถูกรายงานและตรวจสอบ
                          จะถูกระงับหากละเมิดกฎ
                        </p>
                        <p className="text-info">
                          เนื่องจากมีผู้เล่นส่งรายงานเข้ามาจํานวนมาก
                          รายงานที่ไม่มี รายละเอียดเพียงพอ
                          (เวลาในการกระทําผิดของผู้ถูกรายงาน)
                          จะไม่ได้รับการดําเนินการ
                          เว้นแต่ผู้รายงานจะให้ข้อมูลตามที่ ร้องขอ
                          หากไม่ดําเนินการดังกล่าวจะส่งผลให้รายงานไม่ได้
                          ตรวจสอบและถูกปิดโดยไม่มีการแจ้งให้ทราบ
                        </p>
                      </div>
                    ) : selectedOption === "เจตนาโยนเกม" ? (
                      <div>
                        <h1 className="text-center">เจตนาโยนเกม</h1>
                        <h1 className="text-center mb-5">(ระงับ 4 วัน+)</h1>
                        <ul className="d-flex flex-column gap-3">
                          <li>•ไม่ให้ความร่วมมือกับทีม</li>
                          <li>
                            •ฟาร์มป่ามากเกินไปโดยไม่มีประโยชน์หรือให้ความช่วยเหลือทีม
                          </li>
                          <li>
                            •หลีกเลี่ยงการตรวจจับ AFK โดยการเดินเพียงเล็กน้อย
                          </li>
                          <li>•บอกตําแหน่งเพื่อนร่วมทีมให้ฝ่ายตรงข้าม</li>
                          <li>•ขโมยครีปหรือป้อมโดยมีเจตนาร้าย</li>
                          <li>•เดินตามเพื่อนร่วมทีมเพื่อแย่งครีปในเลน</li>
                          <li>
                            •รอใช้ความสามารถหรือไอเทมเพื่อแย่งคิลหลายครั้ง (2
                            ครั้ง+)
                          </li>
                          <li>•ขัดขวางเพื่อนร่วมทีมในการดึงครีปป่า</li>
                          <li>
                            •เก็บทองไว้จํานวนมาก
                            โดยไม่มีเหตุผลที่เป็นประโยชน์ต่อทีม
                          </li>
                          <li>
                            •โจมตีเพื่อนร่วมทีมที่มีเลือดตํ่าด้วยเจตนาไม่ดีจนตาย
                          </li>
                          <li>
                            •แจกคิลให้ศัตรู, ครีป, ป้อม
                            รวมถึงนําฮีโร่ของผู้เล่นที่ตัดการเชื่อมต่อไปแจกให้ศัตรู
                          </li>
                          <li>
                            •การก่อกวนใด ๆ
                            การกระทําไม่ดีโดยมีเจตนาหรือพฤติกรรมที่ทําให้ผู้เล่นคนอื่นรําคาญ
                            หรือส่งผล กระทบต่อเกม
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <h1 className="text-center">แย่งเลน</h1>
                        <h1 className="text-center mb-5">(ระงับ 4 วัน+)</h1>
                        <ul className="d-flex flex-column gap-3">
                          <li>
                            •คุณต้องอ้างสิทธิ์ เลนในช่วงระยะการ "โหวตแบนฮีโร่"
                            ก่อนที่เกมจะเข้าสู้ช่วง "เลือกฮีโร่“
                            ในกรณีที่ผู้เล่นหลายคนเรียกเลนเดียวกันผู้เล่นที่มี
                            MMR สูงกว่าสามารถอ้างสิทธิ์ เลนได
                          </li>
                          <li>
                            •เมื่อระยะการแบนสิ้นสุดลงการอ้างสิทธิ์
                            เลนจะเป็นของผู้เล่นคนนั้นเว้นแต่คุณจะสื่อสารตกลงกันภายหลั
                          </li>
                          <li>
                            •การเรียกเลนหลังจากสิ้นสุดขั้นตอนการแบนจะไม่แทนที่การเรียกเลนก่อนหน้าสําหรับบทบาทหรือเลน
                            เดียวกัน
                          </li>
                          <li>
                            •ผู้เล่นแต่ละคนต้องอ้างสิทธิ์ เลน /
                            บทบาทของตนเป็นรายบุคคล ซึ่งหมายความว่าหากคุณมี MMR
                            สูงสุดและมากับเพื่อนคุณจะไม่ได้รับอนุญาตให้กําหนดหรือเลือกเลนให้เพื่อนของคุณ
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="w-100 mt-auto">
                      <Dropdown
                        options={options}
                        onChange={(e) => selectOption(e)}
                        value={defaultOption}
                        placeholder="Select an option"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ContactArea;
