import React from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import { useState, useEffect } from "react";
import moment from "moment";
import { Wheel } from "react-custom-roulette";
import { useUserContext } from "../contexts/UserContext";
import Countdown from "react-countdown";
import Modal from "react-modal";

function WheelPage() {
  const { user, userAction } = useUserContext();
  const [lastSpinTime, setLastSpinTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [wheels, setWheels] = useState([]);
  const [selectedWheelId, setSelectedWheelId] = useState(null);
  const [selectedWheel, setSelectedWheel] = useState(null);
  const [totalChance, setTotalChance] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [reward, setReward] = useState(null);

  const [segments, setSegments] = useState([]);
  const [segColors, setSegColors] = useState([]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isRealSpin, setIsRealSpin] = useState(false);

  const modalStyles = {
    content: {
      padding: "50px 40px 30px 40px",
      top: "25%",
      left: "40%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      backgroundColor: "#1f2029",
      border: 0,
      width: "25%",
      height: "50%",
      borderRadius: 15,
      boxShadow:
        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    },
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgb(0,0,0,0.5)",
    },
  };

  useEffect(() => {
    getWheels();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      getUserDetail();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      getWheel();
    }
  }, [isLoading, selectedWheelId]);

  const selectWheel = (wheelId) => {
    setSelectedWheelId(wheelId);
    setIsLoading(true);
  };

  const getUserDetail = async () => {
    if (!user) {
      return;
    }
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/me`,
        { headers }
      );

      setLastSpinTime(response.data.data.last_spin_wheel);
    } catch (error) {
      console.log(error);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const getWheel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/wheels/${selectedWheelId}`
      );

      const thisWheel = response.data.data;
      const newSegments = [];
      const newSegColors = [];
      let newTotalChance = 0;
      thisWheel.prizes.map((item) => {
        newSegments.push({
          image: { uri: item.image_url, sizeMultiplier: 0.5 },
          option: item.name,
          style: { background: item.color, textColor: "white" },
        });
        newSegColors.push(item.color);
        newTotalChance += item.chance;
        return true;
      });

      setTotalChance(newTotalChance);
      setSelectedWheel(thisWheel);
      setSegments(newSegments);
      setSegColors(newSegColors);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getWheels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/wheels`
      );

      console.log(response);
      setWheels(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpinClick = () => {
    if (!mustSpin) {
      setMustSpin(true);
    }
  };

  const startSpin = async () => {
    setIsRealSpin(false);
    setReward(null);
    if (mustSpin) {
      return;
    }

    if (moment().diff(moment(lastSpinTime), "days") < 1 || !user) {
      const newPrizeNumber = Math.floor(Math.random() * segments.length);
      setPrizeNumber(newPrizeNumber);
      handleSpinClick();
      return;
    }

    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/wheels/spin/${selectedWheelId}`,
        {},
        { headers }
      );

      for (let i = 0; i < segments.length; i++) {
        if (segments[i].option === response.data.data.name) {
          setPrizeNumber(i);
          setReward(segments[i]);
          setIsRealSpin(true);
          break;
        }
      }
      await getUserDetail();
    } catch (error) {
      console.log(error);
    }

    handleSpinClick();
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <h5 className="d-flex gap-3 align-items-center">
          <i className="fas fa-gem fa-lg" />{" "}
          <span> Congratuation, you get this reward !!</span>
        </h5>
        <div className="mt-4">
          {reward && (
            <img
              style={{
                width: "100%",
                height: 250,
                objectFit: "contain",
              }}
              src={reward.image.uri}
              alt="reward"
            />
          )}
        </div>
        <button
          onClick={closeModal}
          className="btn-danger border-0 rounded w-100 mt-5 py-2"
          type="button"
        >
          Close
        </button>
      </Modal>
      <HeaderOne />
      <main>
        <section className="blog-area black-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {!isLoading && selectedWheelId ? (
                  <div className="d-flex flex-column gap-4">
                    <div
                      className="p-4"
                      style={{ background: "#171717", height: 670 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-white">{selectedWheel.title}</h3>
                        <h5 className="text-white">
                          {lastSpinTime === null ||
                          moment().diff(moment(lastSpinTime), "days") >= 1 ? (
                            "Ready to spin"
                          ) : (
                            <Countdown
                              date={moment(lastSpinTime)
                                .add(1, "days")
                                .valueOf()}
                            />
                          )}
                        </h5>
                      </div>
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: 525, zIndex: 9999 }}
                      >
                        {segments.length && (
                          <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={segments}
                            backgroundColors={segColors}
                            onStopSpinning={() => {
                              if (isRealSpin === true) {
                                setIsOpen(true);
                              }
                              setMustSpin(false);
                            }}
                          />
                        )}
                      </div>
                      {!user.game_username ? (
                        <button
                          type="button"
                          style={{
                            zIndex: 99999,
                            cursor: "not-allowed",
                            background: "#6c757d",
                          }}
                          className="d-flex w-100 text-white rounded border-0 px-4 py-2 justify-content-center"
                        >
                          Sync Game Username First To Spin
                        </button>
                      ) : mustSpin ? (
                        <button
                          type="button"
                          style={{
                            zIndex: 99999,
                            cursor: "not-allowed",
                            background: "#6c757d",
                          }}
                          className="d-flex w-100 text-white rounded border-0 px-4 py-2 justify-content-center"
                        >
                          Wait For Result
                        </button>
                      ) : (
                        <button
                          onClick={startSpin}
                          type="button"
                          style={{ zIndex: 99999 }}
                          className="d-flex w-100 text-white btn-primary rounded border-0 px-4 py-2 justify-content-center"
                        >
                          Spin Now
                        </button>
                      )}
                    </div>
                    <div
                      style={{
                        background: "#171717",
                        maxHeight: 350,
                        overflow: "auto",
                      }}
                      className="p-4 rounded"
                    >
                      <div className="row">
                        <div className="col-md-1">#</div>
                        <div className="col-md-3 text-center">Image</div>
                        <div className="col-md-4 text-center">Name</div>
                        <div className="col-md-4 text-end">Description</div>
                      </div>
                      <hr />
                      {selectedWheel.prizes
                        .sort((a, b) => a.chance - b.chance)
                        .map((prize, index) => {
                          return (
                            <div className="row mb-3" key={prize.id}>
                              <div className="col-md-1">{index + 1}</div>
                              <div className="col-md-3 text-center">
                                <img
                                  style={{
                                    width: 40,
                                    height: 40,
                                    objectFit: "cover",
                                  }}
                                  src={prize.image_url}
                                  alt="item"
                                  className="rounded"
                                />
                              </div>
                              <div className="col-md-4 text-center">
                                {prize.name}
                              </div>
                              <div className="col-md-4 text-end">
                                {prize.description}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      style={{ objectFit: "cover" }}
                      className="w-100 h-100 rounded"
                      src="/img/Pebble_1.png"
                      alt="not-select"
                    />
                    <h1 className="text-white text-center">
                      Select Wheel To Play
                    </h1>
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <div className="blog-sidebar">
                  <div className="blog-widget">
                    <h4 className="widget-title">wheels</h4>
                    <div
                      style={{ height: 550, overflowY: "auto" }}
                      className="rc-post-list"
                    >
                      <ul className="d-flex flex-column gap-4">
                        {wheels.map((wheel) => {
                          return (
                            <button
                              key={wheel.id}
                              className="bg-transparent border-0"
                              type="button"
                              onClick={() => selectWheel(wheel.id)}
                            >
                              <li>
                                <div className="rc-post-thumb">
                                  <img
                                    className="rounded"
                                    src={wheel.image_url}
                                    alt="poster"
                                  />
                                </div>
                                <div className="rc-post-content w-100">
                                  <div className="d-flex flex-column gap-2 w-100">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <h5 className="title">{wheel.title}</h5>
                                    </div>
                                    <div className="d-flex flex-column align-items-start gap-2">
                                      <small className="text-white">
                                        {wheel.description}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </button>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default WheelPage;
