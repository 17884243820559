import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import moment from "moment/moment";
import * as rdd from "react-device-detect";

function ReportHistory() {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/report-player`,
        { headers }
      );
      console.log(response);
      setReports(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg py-5">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-12">
                  <div className="blog-widget h-100">
                    <h4 className="widget-title">Report History</h4>
                    <div
                      style={{ height: 500, overflowY: "scroll" }}
                      className="sidebar-newsletter text-left rounded"
                    >
                      <div className="sn-thumb">
                        <div
                          style={{
                            width: rdd.isMobile ? "300%" : "content",
                          }}
                          className="row bg-secondary p-4 rounded mb-3"
                        >
                          <div className="col-2">
                            <h5 className="my-auto">Username</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Match ID</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Status</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Reason</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Ban Duration</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-end">
                            <h5 className="my-auto">Date</h5>
                          </div>
                        </div>
                        {reports.map((report, index) => {
                          return (
                            <div
                              style={{
                                width: rdd.isMobile ? "300%" : "content",
                              }}
                              className="row bg-secondary p-4 rounded mb-3"
                            >
                              <div className="col-2">
                                <h5 className="my-auto">
                                  {index + 1}. {report.username}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">{report.match_id}</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">{report.status}</h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {report.reason ? report.reason : "-"}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {report.duration ? report.duration : "-"}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <h5 className="my-auto">
                                  {moment(report.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </h5>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ReportHistory;
