import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeaderOne from "../components/header/HeaderOne";
import axios from "axios";
import moment from "moment";
import * as rdd from "react-device-detect";

function WheelHistory() {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/wheels`,
        { headers }
      );
      console.log(response);
      setTransactions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderOne />
      <main>
        <section className="breadcrumb-area breadcrumb-bg py-5">
          <div className="breadcrumb-overflow">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-12">
                  <div className="blog-widget h-100">
                    <h4 className="widget-title">Wheel History</h4>
                    <div
                      style={{ height: 500, overflowY: "scroll" }}
                      className="sidebar-newsletter text-left rounded"
                    >
                      <div className="sn-thumb">
                        <div
                          style={{ width: rdd.isMobile ? "300%" : "content" }}
                          className="row bg-secondary p-4 rounded mb-3"
                        >
                          <div className="col-1 d-flex justify-content-center">
                            <h5 className="my-auto">Order</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Image</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Title</h5>
                          </div>
                          <div className="col-3 d-flex justify-content-center">
                            <h5 className="my-auto">Description</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            <h5 className="my-auto">Wheel Name</h5>
                          </div>
                          <div className="col-2 d-flex justify-content-end">
                            <h5 className="my-auto">Date</h5>
                          </div>
                        </div>
                        {transactions.map((transaction, index) => {
                          return (
                            <div
                              style={{
                                width: rdd.isMobile ? "300%" : "content",
                              }}
                              className="row bg-secondary p-4 rounded mb-3"
                            >
                              <div className="col-1 d-flex justify-content-center">
                                <h5 className="my-auto d-flex justify-content-center">
                                  {index + 1}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  <img
                                    style={{
                                      height: 50,
                                      objectFit: "contain",
                                    }}
                                    className="w-100"
                                    src={transaction.prize.image_url}
                                    alt="item"
                                  />
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {transaction.prize.name}
                                </h5>
                              </div>
                              <div className="col-3 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {transaction.prize.description}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <h5 className="my-auto">
                                  {transaction.wheel.title}
                                </h5>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <h5 className="my-auto">
                                  {moment(transaction.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </h5>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default WheelHistory;
