import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

function Details() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [newBlogs, setNewBlogs] = useState([]);

  useEffect(() => {
    getBlog();
  }, [id]);

  const getBlog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/blogs/${id}`
      );

      console.log(response);
      setBlog(response.data.data.blog);
      setNewBlogs(response.data.data.new_blogs);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <section className="blog-details-area black-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            {blog && (
              <img
                src={blog.image_url}
                alt="wallpaper"
                style={{ width: "100%", height: 400, objectFit: "cover" }}
              />
            )}
          </div>
          <div className="col-lg-8">
            {blog && (
              <div className="blog-item mb-60">
                <div className="blog-thumb mb-50"></div>
                <div className="blog-content blog-details-content">
                  <h4 className="small-title">{blog.description}</h4>
                  <h2 className="title">{blog.title}</h2>
                  <div className="blog-meta">
                    <ul>
                      <li>BY {blog.user.name}</li>
                      <li>
                        <i className="far fa-clock" />
                        {moment(blog.created_at).format(
                          "Do MMM YYYY | h:mm:ss a"
                        )}
                      </li>
                    </ul>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-4">
            <aside className="blog-sidebar">
              <div className="blog-widget">
                <h4 className="widget-title">Recent Post</h4>
                <div className="rc-post-list">
                  <ul>
                    {newBlogs.map((newBlog) => {
                      return (
                        <Link to={`/blog/${newBlog.id}`}>
                          <li className="mb-4">
                            <div className="rc-post-thumb">
                              <img src={newBlog.image_url} alt="wallpaper" />
                            </div>
                            <div className="rc-post-content">
                              <h5 className="title">{newBlog.title}</h5>
                              <span className="date">
                                <i className="far fa-clock" />
                                {moment(newBlog).format("Do MMM YYYY")}
                              </span>
                            </div>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
