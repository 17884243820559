import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
function BlogArea() {
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));

  useEffect(() => {
    getBlogs();
  }, [isLoading]);

  const searchBlogs = (e) => {
    e.preventDefault();
    if (search) {
      setSearchParams({ search: search });
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
    setIsLoading(true);
  };

  const getBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/guest/blogs`,
        {
          params: {
            search: search,
          },
        }
      );

      console.log(response);
      setBlogs(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="blog-area black-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-sidebar">
              <div className="blog-widget">
                <h4 className="widget-title">Blogs</h4>
                <div className="rc-post-list">
                  <ul className="d-flex flex-column gap-4">
                    {blogs.map((blog) => {
                      return (
                        <Link to={`/blog/${blog.id}`}>
                          <li>
                            <div className="rc-post-thumb">
                              <img src={blog.image_url} alt="poster" />
                            </div>
                            <div className="rc-post-content w-100">
                              <div className="d-flex flex-column gap-2 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <h5 className="title">{blog.title}</h5>
                                  <span className="date">
                                    <i className="far fa-clock" />
                                    {moment(blog.created_at).format(
                                      "Do MMM YYYY"
                                    )}
                                  </span>
                                </div>
                                <small>{blog.description}</small>
                              </div>
                            </div>
                          </li>
                        </Link>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="blog-sidebar">
              <div className="blog-widget">
                <h4 className="widget-title">Searching</h4>
                <div className="sidebar-search">
                  <form onSubmit={(e) => searchBlogs(e)}>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      type="text"
                      placeholder="Search..."
                    />
                    <button type="submit">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogArea;
